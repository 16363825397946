// src/components/Modal.tsx
import React, { useEffect, useState } from 'react';
import ApiService from '../services/ApiService';
import { Person, Video } from '../generated-client';
import SocialMediaList from './SocialMediaList';

interface ModalProps {
  person: Person | null;
  onClose: () => void;
}

const PersonDetailsModal: React.FC<ModalProps> = ({ person, onClose }) => {
  const [videos, setVideos] = useState<Video[]>([]);

  useEffect(() => {
    if (!person) {
      return;
    }
    const fetchVideos = async (authorCode: string) => {
      try {
        const response = await ApiService.getVideos(authorCode);
        setVideos(response.videos ?? []);
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };
    if (person?.authorCode) fetchVideos(person.authorCode);
  }, [person]);
  if (!person) {
    return null;
  }
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="close-button" onClick={onClose}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 256 256"
          focusable="false"
          color="rgb(212, 212, 212)"
        >
          <g color="rgb(212, 212, 212)">
            <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
          </g>
        </svg>
      </div>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="profile-container">
          <img
            src={ApiService.getPersonImageURL(person)}
            alt={`${person.firstName} ${person.lastName}`}
            className="profile-picture"
          />
          <div className="profile-details">
            <h1>
              {person.firstName} {person.lastName}
            </h1>
            <p className="suremark-code">/S/{person.authorCode}</p>
            <SocialMediaList person={person} />
          </div>
        </div>
        {videos.length ? (
          <>
            <h2 className="container-title">Verification Videos</h2>
            <div className="profile-container">
              {videos.map((video, i) => (
                <div key={i} className="video-box">
                  <p className="video-title">
                    {video.authorCodes?.map((c) => `/S/${c}`).join(', ')}
                  </p>
                  <video controls>
                    <source src={video.location} type={`video/${video.videoType}`} />
                  </video>
                  <p className="video-title">{video.title}</p>
                </div>
              ))}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default PersonDetailsModal;
