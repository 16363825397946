import React from 'react';
import BreadcrumbTrail from './BreadcrumbTrail';
import './ThanksForApplication.css';
import { Link, useSearchParams } from 'react-router-dom';

const ThanksForApplication: React.FC = () => {
  const [searchParams] = useSearchParams();
  const submissionId = searchParams.get('submissionId');
  return (
    <>
      <BreadcrumbTrail currentStep={2} />
      <h2>Thanks for your payment/grant code submission.</h2>
      <h3>A receipt has been sent to the applicant’s email.</h3>
      <h3>You can schedule your online video interviews below.</h3>
      <div className="text-block">
        If you’re not ready to schedule your interviews at this time, there’s a link to continue
        this process at the bottom of the receipt we emailed you.
      </div>
      <div>
        {submissionId && (
          <Link to={`/schedule?submissionId=${submissionId}`}>
            <button>Schedule Online Interviews</button>
          </Link>
        )}
      </div>
    </>
  );
};

export default ThanksForApplication;
