/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiSendOtpPostRequest
 */
export interface ApiSendOtpPostRequest {
    /**
     * The email address to which the OTP will be sent.
     * @type {string}
     * @memberof ApiSendOtpPostRequest
     */
    email?: string;
    /**
     * Indicates if the registration is in progress.
     * @type {boolean}
     * @memberof ApiSendOtpPostRequest
     */
    registration?: boolean;
}

/**
 * Check if a given object implements the ApiSendOtpPostRequest interface.
 */
export function instanceOfApiSendOtpPostRequest(value: object): value is ApiSendOtpPostRequest {
    return true;
}

export function ApiSendOtpPostRequestFromJSON(json: any): ApiSendOtpPostRequest {
    return ApiSendOtpPostRequestFromJSONTyped(json, false);
}

export function ApiSendOtpPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiSendOtpPostRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'] == null ? undefined : json['email'],
        'registration': json['registration'] == null ? undefined : json['registration'],
    };
}

export function ApiSendOtpPostRequestToJSON(value?: ApiSendOtpPostRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'registration': value['registration'],
    };
}

