/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The subject of the claim.
 * @export
 * @interface ApiMySignatureRequestsSignPostRequestClaimClaimSubject
 */
export interface ApiMySignatureRequestsSignPostRequestClaimClaimSubject {
    /**
     * The URL of the subject associated with the claim.
     * @type {string}
     * @memberof ApiMySignatureRequestsSignPostRequestClaimClaimSubject
     */
    url?: string;
    /**
     * A hash representing the subject.
     * @type {string}
     * @memberof ApiMySignatureRequestsSignPostRequestClaimClaimSubject
     */
    hash?: string;
}

/**
 * Check if a given object implements the ApiMySignatureRequestsSignPostRequestClaimClaimSubject interface.
 */
export function instanceOfApiMySignatureRequestsSignPostRequestClaimClaimSubject(value: object): value is ApiMySignatureRequestsSignPostRequestClaimClaimSubject {
    return true;
}

export function ApiMySignatureRequestsSignPostRequestClaimClaimSubjectFromJSON(json: any): ApiMySignatureRequestsSignPostRequestClaimClaimSubject {
    return ApiMySignatureRequestsSignPostRequestClaimClaimSubjectFromJSONTyped(json, false);
}

export function ApiMySignatureRequestsSignPostRequestClaimClaimSubjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiMySignatureRequestsSignPostRequestClaimClaimSubject {
    if (json == null) {
        return json;
    }
    return {
        
        'url': json['url'] == null ? undefined : json['url'],
        'hash': json['hash'] == null ? undefined : json['hash'],
    };
}

export function ApiMySignatureRequestsSignPostRequestClaimClaimSubjectToJSON(value?: ApiMySignatureRequestsSignPostRequestClaimClaimSubject | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'url': value['url'],
        'hash': value['hash'],
    };
}

