import React from 'react';
import './BreadcrumbTrail.css'; // Import the CSS file for styling
import { Link } from 'react-router-dom';

interface BreadcrumbTrailProps {
  currentStep: number;
}

const BreadcrumbTrail: React.FC<BreadcrumbTrailProps> = ({ currentStep }) => {
  const steps = [
    'Sponsors & Application Fee',
    'Schedule Interviews & Photo Upload',
    'Interviews, Approvals & Social Posts',
  ];

  return (
    <div className="breadcrumb-trail">
      {steps.map((step, index) => {
        const index1 = index + 1;
        return (
          <div
            key={index1}
            className={`breadcrumb-item ${
              index1 < currentStep
                ? 'completed'
                : index1 === currentStep
                ? 'current'
                : 'yet-to-come'
            }`}
          >
            <img src={`images/${index1}.svg`} alt={`Step ${index1}`} />
            <span>{step}</span>
          </div>
        );
      })}
      <Link className="questions-link" to={process.env.REACT_APP_FAQ_URL!}>
        Questions?
      </Link>
    </div>
  );
};

export default BreadcrumbTrail;
