/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiVerifyOtpPost200Response
 */
export interface ApiVerifyOtpPost200Response {
    /**
     * JSON Web Token (JWT) for authenticated operations.
     * @type {string}
     * @memberof ApiVerifyOtpPost200Response
     */
    token?: string;
}

/**
 * Check if a given object implements the ApiVerifyOtpPost200Response interface.
 */
export function instanceOfApiVerifyOtpPost200Response(value: object): value is ApiVerifyOtpPost200Response {
    return true;
}

export function ApiVerifyOtpPost200ResponseFromJSON(json: any): ApiVerifyOtpPost200Response {
    return ApiVerifyOtpPost200ResponseFromJSONTyped(json, false);
}

export function ApiVerifyOtpPost200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVerifyOtpPost200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'token': json['token'] == null ? undefined : json['token'],
    };
}

export function ApiVerifyOtpPost200ResponseToJSON(value?: ApiVerifyOtpPost200Response | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'token': value['token'],
    };
}

