/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApiMyvouchingUrlsPostRequestVouchersInner } from './ApiMyvouchingUrlsPostRequestVouchersInner';
import {
    ApiMyvouchingUrlsPostRequestVouchersInnerFromJSON,
    ApiMyvouchingUrlsPostRequestVouchersInnerFromJSONTyped,
    ApiMyvouchingUrlsPostRequestVouchersInnerToJSON,
} from './ApiMyvouchingUrlsPostRequestVouchersInner';

/**
 * 
 * @export
 * @interface ApiMyvouchingUrlsPostRequest
 */
export interface ApiMyvouchingUrlsPostRequest {
    /**
     * List of vouchers with their IDs and URLs
     * @type {Array<ApiMyvouchingUrlsPostRequestVouchersInner>}
     * @memberof ApiMyvouchingUrlsPostRequest
     */
    vouchers?: Array<ApiMyvouchingUrlsPostRequestVouchersInner>;
    /**
     * The social media URL of the applicant
     * @type {string}
     * @memberof ApiMyvouchingUrlsPostRequest
     */
    applicantUrl?: string;
    /**
     * The submission ID
     * @type {string}
     * @memberof ApiMyvouchingUrlsPostRequest
     */
    submissionId?: string;
}

/**
 * Check if a given object implements the ApiMyvouchingUrlsPostRequest interface.
 */
export function instanceOfApiMyvouchingUrlsPostRequest(value: object): value is ApiMyvouchingUrlsPostRequest {
    return true;
}

export function ApiMyvouchingUrlsPostRequestFromJSON(json: any): ApiMyvouchingUrlsPostRequest {
    return ApiMyvouchingUrlsPostRequestFromJSONTyped(json, false);
}

export function ApiMyvouchingUrlsPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiMyvouchingUrlsPostRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'vouchers': json['vouchers'] == null ? undefined : ((json['vouchers'] as Array<any>).map(ApiMyvouchingUrlsPostRequestVouchersInnerFromJSON)),
        'applicantUrl': json['applicant_url'] == null ? undefined : json['applicant_url'],
        'submissionId': json['submission_id'] == null ? undefined : json['submission_id'],
    };
}

export function ApiMyvouchingUrlsPostRequestToJSON(value?: ApiMyvouchingUrlsPostRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'vouchers': value['vouchers'] == null ? undefined : ((value['vouchers'] as Array<any>).map(ApiMyvouchingUrlsPostRequestVouchersInnerToJSON)),
        'applicant_url': value['applicantUrl'],
        'submission_id': value['submissionId'],
    };
}

