import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ApiService from '../services/ApiService';
import { ResponseError } from '../generated-client';
import BreadcrumbTrail from './BreadcrumbTrail';
import './ProceedToPayment.css';

const ProceedToPayment: React.FC = () => {
  const [searchParams] = useSearchParams();
  const submissionId = searchParams.get('submissionId');
  const [code, setCode] = useState<string>('');
  const [isApplying, setIsApplying] = useState<boolean>(false);
  const isApplyingRef = useRef(isApplying);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    isApplyingRef.current = isApplying;
  }, [isApplying]);

  useEffect(() => {
    const applyCodeWithRetry = async (retryCount: number = 12, delay: number = 10000) => {
      try {
        if (isApplyingRef.current) {
          await ApiService.postGrantCode(submissionId!, code);
          navigate(`/thank-you?submissionId=${submissionId}`);
        }
        setIsApplying(false);
        setError(null);
      } catch (err) {
        if (err instanceof ResponseError && err.response.status === 409) {
          setIsApplying(false);
          setError('The grant code is either invalid or has already been applied.');
        } else if (retryCount > 0 && isApplyingRef.current) {
          setTimeout(() => applyCodeWithRetry(retryCount - 1, delay), delay);
        } else {
          setIsApplying(false);
          setError('Failed to apply the code after multiple attempts. Please try again.');
        }
      }
    };

    if (!submissionId) {
      setError('Invalid submission ID');
    } else if (isApplying && code.length > 0) {
      applyCodeWithRetry();
    }
  }, [isApplying, submissionId, code, navigate]);
  // todo: what happens if the user presses Cancel and then quickly Apply again?
  // would there be calls to the API with both new and old values of `code`?

  const handleApplyClick = () => {
    setIsApplying(true);
  };

  const handleProceedClick = () => {
    window.location.href = `${process.env.REACT_APP_CHECKOUT_URL}?submissionId=${submissionId}`; // Navigate to the absolute URL
  };

  const handleCancelClick = () => {
    setIsApplying(false);
    setError(null);
  };

  return (
    <div>
      <BreadcrumbTrail currentStep={1} />
      {isApplying ? (
        <div>
          <p>Trying to apply the code... This may take a while...</p>
          <button className="modal-button" onClick={handleCancelClick}>
            Cancel
          </button>
        </div>
      ) : (
        <div>
          <h3>If you have a Grant Code, please provide it.</h3>
          <div className="input-container">
            <div className="input-row">
              <input
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="Enter code"
              />
              {submissionId && (
                <button className="modal-button" onClick={handleApplyClick}>
                  Apply
                </button>
              )}
            </div>
            OR
            <div className="solid-line"></div>
            No Grant Code? Select “Proceed to Payment”
            <div className="proceed-button-row">
              <button className="modal-button" onClick={handleProceedClick}>
                Proceed to Payment
              </button>
            </div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProceedToPayment;
