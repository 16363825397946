/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiVerifyOtpPostRequest
 */
export interface ApiVerifyOtpPostRequest {
    /**
     * The email address associated with the OTP.
     * @type {string}
     * @memberof ApiVerifyOtpPostRequest
     */
    email?: string;
    /**
     * The One-Time Password to be verified.
     * @type {string}
     * @memberof ApiVerifyOtpPostRequest
     */
    otp?: string;
}

/**
 * Check if a given object implements the ApiVerifyOtpPostRequest interface.
 */
export function instanceOfApiVerifyOtpPostRequest(value: object): value is ApiVerifyOtpPostRequest {
    return true;
}

export function ApiVerifyOtpPostRequestFromJSON(json: any): ApiVerifyOtpPostRequest {
    return ApiVerifyOtpPostRequestFromJSONTyped(json, false);
}

export function ApiVerifyOtpPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVerifyOtpPostRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'] == null ? undefined : json['email'],
        'otp': json['otp'] == null ? undefined : json['otp'],
    };
}

export function ApiVerifyOtpPostRequestToJSON(value?: ApiVerifyOtpPostRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'otp': value['otp'],
    };
}

