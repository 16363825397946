/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiPostPostRequest
 */
export interface ApiPostPostRequest {
    /**
     * The location associated with the post.
     * @type {string}
     * @memberof ApiPostPostRequest
     */
    location?: string;
}

/**
 * Check if a given object implements the ApiPostPostRequest interface.
 */
export function instanceOfApiPostPostRequest(value: object): value is ApiPostPostRequest {
    return true;
}

export function ApiPostPostRequestFromJSON(json: any): ApiPostPostRequest {
    return ApiPostPostRequestFromJSONTyped(json, false);
}

export function ApiPostPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiPostPostRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'location': json['location'] == null ? undefined : json['location'],
    };
}

export function ApiPostPostRequestToJSON(value?: ApiPostPostRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'location': value['location'],
    };
}

