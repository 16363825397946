import React, { useState, useEffect, useCallback } from 'react';
import RequestOTP from './RequestOTP';
import ScheduleScreen from './ScheduleScreen';
import ApiService from '../services/ApiService';
import { ApiMybookingsGet200ResponseInner, ResponseError } from '../generated-client';
import { useLocation } from 'react-router-dom';
import BreadcrumbTrail from './BreadcrumbTrail';
import PhotoUploadScreen from './PhotoUploadScreen';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface ScheduleAndPhotoFormProps {
  onUserDataChange: (user: { email: string }) => void;
}

const ScheduleAndPhotoForm: React.FC<ScheduleAndPhotoFormProps> = ({ onUserDataChange }) => {
  const query = useQuery();
  const submissionId = query.get('submissionId');
  const [showRequestOTP, setShowRequestOTP] = useState<boolean | string>(false);
  const [text, setText] = useState<string>('Loading...');
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [bookingSummary, setBookingSummary] = useState<
    ApiMybookingsGet200ResponseInner[] | undefined
  >(undefined);

  const handleOTPVerified = ({ email }: { email: string }) => {
    setShowRequestOTP(false);
    setText('Loading...');
    setCurrentStep(0);
    getMyBookings(false); // Skip token verification when re-triggering
    onUserDataChange({ email });
  };

  const handleNextBooking = async () => {
    await getMyBookings(false); // Skip token verification when moving to the next booking
  };

  const handleSubmissionConflict = () => {
    setShowRequestOTP('You need to log in as a different user to proceed.');
  };

  const getMyBookings = useCallback(async (verifyToken: boolean = true) => {
    try {
      if (verifyToken) {
        await ApiService.verifyToken(onUserDataChange); // Verify token only if it's the initial load
      }
      const myBookings = await ApiService.getMyBookings();
      setBookingSummary(myBookings);
      setCurrentStep(myBookings.length < 2 ? 1 : 2);
    } catch (error) {
      if (error instanceof ResponseError && error.response.status === 401) {
        setShowRequestOTP(true);
        return;
      } else if (error instanceof ResponseError && error.response.status === 400) {
        setText('Invalid Submission ID');
      } else {
        setText('Error calling API');
        console.error('Unknown error', error);
      }
    }
  }, []);

  useEffect(() => {
    getMyBookings(); // Initial call with token verification
  }, [getMyBookings]);

  return (
    <>
      <BreadcrumbTrail currentStep={2} />
      {showRequestOTP ? (
        <RequestOTP
          onOTPVerified={handleOTPVerified}
          registration={true}
          customMessage={typeof showRequestOTP === 'string' ? showRequestOTP : undefined}
        />
      ) : submissionId === null ? (
        <h3>Invalid Submission ID</h3>
      ) : bookingSummary && bookingSummary.length < 2 && currentStep === 1 ? (
        <ScheduleScreen
          onNext={handleNextBooking}
          onSubmissionConflict={handleSubmissionConflict}
          bookingSummary={bookingSummary}
          submissionId={submissionId}
        />
      ) : currentStep === 2 ? (
        <PhotoUploadScreen onNext={() => setCurrentStep(3)} />
      ) : currentStep === 3 ? (
        <div className="instructions-container">
          <h1>Thank you!</h1>
          {bookingSummary && bookingSummary.length >= 2 && (
            <div>
              {bookingSummary[0].isoStartTime && (
                <p>
                  Sponsor 1 Interview - confirmed for{' '}
                  {new Date(bookingSummary[0].isoStartTime).toString()}
                </p>
              )}
              {bookingSummary[1].isoStartTime && (
                <p>
                  Sponsor 2 Interview - confirmed for{' '}
                  {new Date(bookingSummary[1].isoStartTime).toString()}
                </p>
              )}
            </div>
          )}
          <h2>Please check your email for information in preparation for the interview.</h2>
          <p>
            <span className="green-item">NOTE:</span> We have also sent appointment invitations and
            Zoom links to each of your sponsors for their respective interviews. We also suggest you
            remind them of their video interviews a day or two before the appointment.
          </p>
        </div>
      ) : (
        <h3>{text}</h3>
      )}
    </>
  );
};

export default ScheduleAndPhotoForm;
