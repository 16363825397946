import React from 'react';

const SupportedSocialMediaOutlets: React.FC = () => {
  return (
    <div>
      <h2>Supported Social Media Outlets</h2>
      <div className="social-media-container">
        <div className="social-media-icons">
          <div className="social-media-item">
            <img src="/images/TwitterX.png" alt="Twitter X" />
            <p>Twitter X</p>
          </div>
          <div className="social-media-item">
            <img src="/images/LinkedIn.png" alt="LinkedIn" />
            <p>LinkedIn</p>
          </div>
          <div className="social-media-item">
            <img src="/images/facebook.png" alt="Facebook" />
            <p>Facebook</p>
          </div>
          <div className="social-media-item">
            <img src="/images/Instagram.png" alt="Instagram" />
            <p>Instagram</p>
          </div>
          <div className="social-media-item">
            <img src="/images/youtube.png" alt="YouTube" />
            <p>YouTube</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportedSocialMediaOutlets;
