import React from 'react';

interface IconButtonProps {
  src: string;
  alt: string;
  link: string;
}

const IconButton: React.FC<IconButtonProps> = ({ src, alt, link }) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <img src={src} alt={alt} />
    </a>
  );
};

export default IconButton;
