import React, { useState } from 'react';
import PhotoUpload from './PhotoUpload';
import ApiService from '../services/ApiService';

const PhotoUploadScreen: React.FC<{ onNext: (data: unknown) => void }> = ({ onNext }) => {
  const [photo, setPhoto] = useState<File | null>(null);

  const handlePhotoChange = (selectedPhoto: File | null) => {
    setPhoto(selectedPhoto);
  };

  const handleUpload = async () => {
    if (photo) {
      const reader = new FileReader();
      reader.readAsDataURL(photo);
      reader.onloadend = async () => {
        const base64data = reader.result;
        try {
          await ApiService.postImage(base64data as string); // todo:
          onNext({});
          alert('Photo uploaded successfully');
        } catch (error) {
          alert('Error uploading file!');
        }
      };
    }
  };

  return (
    <div className="upload-image-container">
      <h2>
        Please upload a photo of the SureMark Digital ID Applicant as you'd like it to appear in our
        directory.
      </h2>
      <br />
      <h3>
        Image must be 400x400 pixels with a file size up to 500 Kbytes. In most cases, your LinkedIn
        Profile image can work.
      </h3>
      <br />
      <PhotoUpload onPhotoChange={handlePhotoChange} />
      <button className="modal-button" onClick={handleUpload}>
        Upload
      </button>
    </div>
  );
};

export default PhotoUploadScreen;
