import React, { useEffect, useState } from 'react';
import RequestOTP from '../RequestOTP';
import {
  ApiMySignatureRequestsGet200ResponseSignatureRequestsInner,
  ResponseError,
} from '../../generated-client';
import ApiService from '../../services/ApiService';
import PassphraseModal from '../PassphraseModal';
import { PrivateKey } from 'openpgp';
import SigningAsset from './SigningAsset';
import SigningRequests from './SigningRequests';

interface SignProps {
  onUserDataChange: (user: { email: string }) => void;
}

const Sign: React.FC<SignProps> = ({ onUserDataChange }) => {
  const [myAssets, setMyAssets] = useState<
    ApiMySignatureRequestsGet200ResponseSignatureRequestsInner[] | null
  >(null);
  const [showRequestOTP, setShowRequestOTP] = useState<boolean>(false);
  const [selectedAsset, setSelectedAsset] = useState<{ id: string; assetHash: string } | null>(
    null
  );
  const [selectedKey, setSelectedKey] = useState<string | null>(null); // armored and encrypted version of the private key
  const [decryptedKey, setDecryptedKey] = useState<PrivateKey | null>(null); // decrypted version of the private key
  const [authorCode, setAuthorCode] = useState<string | null>(null);
  const [comment, setComment] = useState<string>('');

  const handleSign = async ({
    id,
    assetHash,
    comment,
  }: {
    id: string;
    assetHash: string;
    comment: string;
  }) => {
    try {
      if (!decryptedKey && !selectedKey) {
        const { secretKey } = await ApiService.getMyKey();
        if (secretKey) {
          setSelectedKey(secretKey);
        } else {
          const { authorCode } = await ApiService.getMyAuthorCode();
          if (!authorCode) {
            throw new Error('Failed to determine the author code');
          }
          setAuthorCode(authorCode);
        }
      }
      setSelectedAsset({ id, assetHash });
      setComment(comment); // Store the comment in the state
    } catch (error) {
      if (error instanceof ResponseError) {
        if (error.response.status === 401) {
          setShowRequestOTP(true);
        }
      } else {
        console.error('Unknown error', error);
      }
    }
  };

  const fetchEntries = async () => {
    try {
      const entries = await ApiService.getMySignatureRequests();
      setMyAssets(entries?.signatureRequests || []);
    } catch (error) {
      if (error instanceof ResponseError) {
        if (error.response.status === 401) {
          setShowRequestOTP(true);
        }
      } else {
        console.error('Unknown error', error);
      }
    }
  };

  const handleOTPVerified = (user: { email: string }) => {
    setShowRequestOTP(false);
    setSelectedAsset(null);
    fetchEntries();
    onUserDataChange(user);
  };

  useEffect(() => {
    fetchEntries();
  }, []);

  return (
    <>
      {showRequestOTP ? (
        <RequestOTP onOTPVerified={handleOTPVerified} />
      ) : selectedAsset && decryptedKey ? (
        <SigningAsset
          assetHash={selectedAsset.assetHash}
          assetUrl={myAssets!.find((a) => a.assetHash === selectedAsset.assetHash)!.url!}
          signatureRequestId={selectedAsset.id}
          signingKey={decryptedKey}
          comment={comment} // Pass the comment to SigningAsset
          onClose={async () => {
            setSelectedAsset(null);
            setComment('');
            await fetchEntries();
          }}
        />
      ) : selectedAsset && (selectedKey != null || authorCode != null) ? (
        <PassphraseModal
          signingKeyOrAuthorCode={
            selectedKey != null ? { signingKey: selectedKey } : { authorCode: authorCode! }
          }
          sign={setDecryptedKey}
          onClose={() => setSelectedAsset(null)}
        />
      ) : (
        <SigningRequests signingRequests={myAssets} handleSign={handleSign} />
      )}
    </>
  );
};

export default Sign;
