import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import './PhotoUpload.css';

interface PhotoUploadProps {
  onPhotoChange: (file: File | null) => void;
}

const PhotoUpload: React.FC<PhotoUploadProps> = ({ onPhotoChange }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0] || null;
      setSelectedFile(file);
      onPhotoChange(file);
    },
    multiple: false,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/gif': ['.gif'],
      'image/bmp': ['.bmp'],
      'image/webp': ['.webp'],
      'image/tiff': ['.tiff', '.tif'],
      'image/avif': ['.avif'],
    },
  });

  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
    } else {
      setPreview(null);
    }
  }, [selectedFile]);

  return (
    <div {...getRootProps()} className="dropzone">
      <input {...getInputProps()} />
      {preview ? (
        <div>
          <img src={preview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
          <p>Image selected. Drag 'n' drop to replace or click to change.</p>
        </div>
      ) : (
        <p>Drag 'n' drop an image file here, or click to select an image file</p>
      )}
    </div>
  );
};

export default PhotoUpload;
