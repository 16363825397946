import React from 'react';

interface AlphabetNavProps {
  activeLetters: string[];
  onLetterClick: (letter: string) => void;
}

const AlphabetNav: React.FC<AlphabetNavProps> = ({ activeLetters, onLetterClick }) => {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  return (
    <div className="alphabet-nav">
      {letters.map((letter) => (
        <button
          key={letter}
          onClick={() => onLetterClick(letter)}
          disabled={!activeLetters.includes(letter)}
        >
          {letter}
        </button>
      ))}
    </div>
  );
};

export default AlphabetNav;
