import React from 'react';
import { Link } from 'react-router-dom';

const DropdownMenu = () => {
  return (
    <div className="dropdown">
      <Link to={process.env.REACT_APP_UNDERSTANDING_APPLICATION_PROCESS_URL!}>Understanding the Application Process</Link>
      <Link to={process.env.REACT_APP_APPLY_URL!}>Application Form</Link>
    </div>
  );
};

export default DropdownMenu;
