import React, { useEffect, useState } from 'react';
import BreadcrumbTrail from './BreadcrumbTrail';
import SupportedSocialMediaOutlets from './SocialMediaOutlets';

const ZohoFormEmbed: React.FC = () => {
  const iframeSrc = process.env.REACT_APP_ONBOARDING_FORM_URL;
  const [reloadCount, setReloadCount] = useState(0);

  const iframeId = 'zoho-frame';

  useEffect(() => {
    const iframe = document.getElementById(iframeId) as HTMLIFrameElement;

    const handleLoad = () => {
      setReloadCount((prevCount) => prevCount + 1);
    };

    if (iframe) {
      iframe.addEventListener('load', handleLoad);
    }

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      if (iframe) {
        iframe.removeEventListener('load', handleLoad);
      }
    };
  }, [iframeId]);

  return (
    <>
      <BreadcrumbTrail currentStep={1} />
      <div className="form-header">
        <h2>Let's get started!</h2>
        {/* Only show the message before the third load */}
        {reloadCount < 3 && <h3>We will begin by sending you a one time password (OTP).</h3>}
        {/* Only show the message before the third load */}
        {reloadCount >= 3 && (
          <h3>Next, we will collect a few details about the Applicant and 2 Sponsors.</h3>
        )}
      </div>
      {reloadCount === 0 && <div className="loading">Loading...</div>}
      <div className={reloadCount ? 'zoho-embed' : 'zoho-over'}>
        <iframe id={iframeId} title="Onboarding an author" src={iframeSrc}></iframe>
        {reloadCount >= 3 && (
          <div>
            <SupportedSocialMediaOutlets />
            <div className="asterisk-text">
              *Email addresses will only be used for verification and video approval process.
              <br />
              No solicitations and we will not sell your personal information.
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ZohoFormEmbed;
