import { Claim, SignedClaim } from '@suremark-digital/suremark-utils';
import { canonicalize } from 'json-canonicalize';
import { createMessage, PrivateKey, sign } from 'openpgp';

// todo: utils?
export async function buildAndSignClaim({
  type,
  assetUrl,
  payload,
  assetHash,
  signingKeys,
}: {
  type: string;
  assetUrl: string;
  payload: any;
  assetHash: string;
  signingKeys: PrivateKey[];
}): Promise<SignedClaim> {
  // Build the claim
  const claim: Claim = {
    suremark: {
      version: 1,
      type: type,
    },
    subject: {
      url: assetUrl,
      hash: assetHash,
    },
    payload,
  };

  // Canonicalize the claim
  const canonicalized = canonicalize(claim);

  // Sign
  const message = await createMessage({ text: canonicalized, format: 'utf8' });
  const signature = (await sign({
    format: 'armored',
    message,
    signingKeys,
    detached: true,
  })) as string;

  return { claim, signature };
}
