/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApiMySignatureRequestsGet200ResponseSignatureRequestsInner } from './ApiMySignatureRequestsGet200ResponseSignatureRequestsInner';
import {
    ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerFromJSON,
    ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerFromJSONTyped,
    ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerToJSON,
} from './ApiMySignatureRequestsGet200ResponseSignatureRequestsInner';

/**
 * 
 * @export
 * @interface ApiMySignatureRequestsGet200Response
 */
export interface ApiMySignatureRequestsGet200Response {
    /**
     * 
     * @type {Array<ApiMySignatureRequestsGet200ResponseSignatureRequestsInner>}
     * @memberof ApiMySignatureRequestsGet200Response
     */
    signatureRequests?: Array<ApiMySignatureRequestsGet200ResponseSignatureRequestsInner>;
}

/**
 * Check if a given object implements the ApiMySignatureRequestsGet200Response interface.
 */
export function instanceOfApiMySignatureRequestsGet200Response(value: object): value is ApiMySignatureRequestsGet200Response {
    return true;
}

export function ApiMySignatureRequestsGet200ResponseFromJSON(json: any): ApiMySignatureRequestsGet200Response {
    return ApiMySignatureRequestsGet200ResponseFromJSONTyped(json, false);
}

export function ApiMySignatureRequestsGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiMySignatureRequestsGet200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'signatureRequests': json['signature_requests'] == null ? undefined : ((json['signature_requests'] as Array<any>).map(ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerFromJSON)),
    };
}

export function ApiMySignatureRequestsGet200ResponseToJSON(value?: ApiMySignatureRequestsGet200Response | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'signature_requests': value['signatureRequests'] == null ? undefined : ((value['signatureRequests'] as Array<any>).map(ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerToJSON)),
    };
}

