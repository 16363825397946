/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiMyimagePostRequest
 */
export interface ApiMyimagePostRequest {
    /**
     * Base64 encoded photo
     * @type {string}
     * @memberof ApiMyimagePostRequest
     */
    image?: string;
}

/**
 * Check if a given object implements the ApiMyimagePostRequest interface.
 */
export function instanceOfApiMyimagePostRequest(value: object): value is ApiMyimagePostRequest {
    return true;
}

export function ApiMyimagePostRequestFromJSON(json: any): ApiMyimagePostRequest {
    return ApiMyimagePostRequestFromJSONTyped(json, false);
}

export function ApiMyimagePostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiMyimagePostRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'image': json['image'] == null ? undefined : json['image'],
    };
}

export function ApiMyimagePostRequestToJSON(value?: ApiMyimagePostRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'image': value['image'],
    };
}

