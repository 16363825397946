import React, { useEffect, useState } from 'react';
import RequestOTP from './RequestOTP';
import { ExtendedVideo, ResponseError } from '../generated-client';
import MyVideos from './MyVideos';
import ApiService from '../services/ApiService';
import PassphraseModal from './PassphraseModal';
import { PrivateKey } from 'openpgp';
import SigningInterview from './SigningInterview';
import BreadcrumbTrail from './BreadcrumbTrail';

interface SignOffProps {
  onUserDataChange: (user: { email: string }) => void;
}

const SignOff: React.FC<SignOffProps> = ({ onUserDataChange }) => {
  const [myVideos, setMyVideos] = useState<ExtendedVideo[] | null>(null);
  const [showRequestOTP, setShowRequestOTP] = useState<boolean>(false);
  const [selectedVideo, setSelectedVideo] = useState<ExtendedVideo | null>(null);
  const [selectedKey, setSelectedKey] = useState<string | null>(null); // armored and encrypted version of the private key
  const [decryptedKey, setDecryptedKey] = useState<PrivateKey | null>(null); // decrypted version of the private key
  const [authorCode, setAuthorCode] = useState<string | null>(null);

  const handleSign = async (storageKey: string) => {
    const video = myVideos?.find((entry) => entry.key === storageKey);
    try {
      if (video) {
        if (!decryptedKey && !selectedKey) {
          const { secretKey } = await ApiService.getMyKey();
          if (secretKey) {
            setSelectedKey(secretKey);
          } else {
            const { authorCode } = await ApiService.getMyAuthorCode();
            if (!authorCode) {
              throw new Error('Failed to determine the author code');
            }
            setAuthorCode(authorCode);
          }
        }
        setSelectedVideo(video);
      }
    } catch (error) {
      if (error instanceof ResponseError) {
        if (error.response.status === 401) {
          setShowRequestOTP(true);
        }
      } else {
        console.error('Unknown error', error);
      }
    }
  };

  const fetchEntries = async () => {
    try {
      const entries = await ApiService.getMyVideos();
      setMyVideos(entries?.videos || []);
    } catch (error) {
      if (error instanceof ResponseError) {
        if (error.response.status === 401) {
          setShowRequestOTP(true);
        }
      } else {
        console.error('Unknown error', error);
      }
    }
  };

  const handleOTPVerified = ({ email }: { email: string }) => {
    setShowRequestOTP(false);
    setSelectedVideo(null);
    fetchEntries();
    onUserDataChange({ email });
  };

  useEffect(() => {
    fetchEntries();
  }, []);

  return (
    <>
      <BreadcrumbTrail currentStep={3} />
      <>
        {showRequestOTP ? (
          <RequestOTP onOTPVerified={handleOTPVerified} />
        ) : selectedVideo && decryptedKey ? (
          <SigningInterview
            video={selectedVideo}
            signingKey={decryptedKey}
            onClose={async () => {
              setSelectedVideo(null);
              await fetchEntries();
            }}
          />
        ) : selectedVideo?.location && (selectedKey != null || authorCode != null) ? (
          <PassphraseModal
            signingKeyOrAuthorCode={
              selectedKey != null ? { signingKey: selectedKey } : { authorCode: authorCode! }
            }
            sign={setDecryptedKey}
            onClose={() => setSelectedVideo(null)}
          />
        ) : (
          <MyVideos myVideos={myVideos} handleSign={handleSign} />
        )}
      </>
    </>
  );
};

export default SignOff;
