import React, { ChangeEvent, useState } from 'react';
import './Search.css';

interface SearchProps {
  query: string;
  onSearchChange: (query: string) => void;
}

const Search: React.FC<SearchProps> = ({ query, onSearchChange }) => {
  const [showInput, setShowInput] = useState(false);

  const handleIconClick = () => {
    setShowInput(!showInput);
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    onSearchChange(event.target.value);
  };

  return (
    <div className="search-container">
      <button className="search-icon" onClick={handleIconClick}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="18" height="18">
          <path
            d="M232.49,215.51,185,168a92.12,92.12,0,1,0-17,17l47.53,47.54a12,12,0,0,0,17-17ZM44,112a68,68,0,1,1,68,68A68.07,68.07,0,0,1,44,112Z"
            fill="currentColor"
          ></path>
        </svg>
      </button>
      {showInput && (
        <input
          className="search-input"
          type="text"
          value={query}
          placeholder="Search by last name"
          onChange={handleSearch}
        />
      )}
    </div>
  );
};

export default Search;
