import React from 'react';
import ApiService from '../services/ApiService';
import { Person } from '../generated-client';

interface PeopleListProps {
  people: Person[];
  onViewDetails: (person: Person) => void;
}

const PeopleList: React.FC<PeopleListProps> = ({ people, onViewDetails }) => {
  return (
    <div className="people-list">
      {people.map((person, i) => (
        <div key={i} className="person-card">
          <div className="person-image-and-description">
            <img
              src={ApiService.getPersonImageURL(person)}
              alt={`${person.firstName} ${person.lastName}`}
              className="person-image"
            />
            <div className="person-description">
              <h3 className="person-name">
                {person.firstName} {person.lastName}
              </h3>
            </div>
          </div>
          {!person.authorCode ? (
            <span>Application Pending</span>
          ) : (
            <button onClick={() => onViewDetails(person)}>View Details</button>
          )}
        </div>
      ))}
    </div>
  );
};

export default PeopleList;
