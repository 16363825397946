/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Video } from './Video';
import {
    VideoFromJSON,
    VideoFromJSONTyped,
    VideoToJSON,
} from './Video';

/**
 * 
 * @export
 * @interface ApiPeopleCodeVideosGet200Response
 */
export interface ApiPeopleCodeVideosGet200Response {
    /**
     * 
     * @type {Array<Video>}
     * @memberof ApiPeopleCodeVideosGet200Response
     */
    videos?: Array<Video>;
}

/**
 * Check if a given object implements the ApiPeopleCodeVideosGet200Response interface.
 */
export function instanceOfApiPeopleCodeVideosGet200Response(value: object): value is ApiPeopleCodeVideosGet200Response {
    return true;
}

export function ApiPeopleCodeVideosGet200ResponseFromJSON(json: any): ApiPeopleCodeVideosGet200Response {
    return ApiPeopleCodeVideosGet200ResponseFromJSONTyped(json, false);
}

export function ApiPeopleCodeVideosGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiPeopleCodeVideosGet200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'videos': json['videos'] == null ? undefined : ((json['videos'] as Array<any>).map(VideoFromJSON)),
    };
}

export function ApiPeopleCodeVideosGet200ResponseToJSON(value?: ApiPeopleCodeVideosGet200Response | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'videos': value['videos'] == null ? undefined : ((value['videos'] as Array<any>).map(VideoToJSON)),
    };
}

