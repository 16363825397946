import React, { useEffect, useState } from 'react';
import { ExtendedVideo, ResponseError } from '../generated-client';
import { createMessage, sign, PrivateKey } from 'openpgp';
import { hash, uint8ArrayToHex, filterMdat, buildTree } from '@suremark-digital/suremark-utils';
import ApiService from '../services/ApiService';

interface ModalProps {
  video: ExtendedVideo;
  signingKey: PrivateKey;
  email?: string;
  onClose: () => void;
}

const SigningInterview: React.FC<ModalProps> = ({ video, signingKey, onClose }) => {
  const [status, setStatus] = useState<string>('Processing… Please don’t close this page');
  useEffect(() => {
    const signVideo = async () => {
      try {
        // download video
        const videoURL = video.location!;
        const response = await fetch(videoURL);
        const videoBlob = await response.blob();
        if (videoBlob.type !== 'video/mp4') {
          alert("Could not sign the file. It's not an MP4 file");
          return;
        }
        const binaryHash = await hash(filterMdat(buildTree(await videoBlob.arrayBuffer())));
        const message = await createMessage({ binary: binaryHash });
        const signature = await sign({
          format: 'armored',
          message,
          signingKeys: [signingKey],
          detached: true,
        });
        const hashHex = uint8ArrayToHex(binaryHash);
        console.log('Video signed successfully, now sending the signature');
        await ApiService.postSignature(hashHex, signature as string);
        setStatus('Video signed successfully');
      } catch (error) {
        if (error instanceof ResponseError && error.response.status === 409) {
          setStatus(
            'There is already a signature for this file in the database. Please contact the administrator if you believe this is an error.'
          );
        } else {
          setStatus('Failed to sign the video or send the signature');
        }
        console.error(error);
      }
    };
    signVideo();
  }, [signingKey, video]);

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="close-button" onClick={onClose}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 256 256"
          focusable="false"
          color="rgb(212, 212, 212)"
        >
          <g color="rgb(212, 212, 212)">
            <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
          </g>
        </svg>
      </div>
      <h2>{status}</h2>
    </div>
  );
};
export default SigningInterview;
