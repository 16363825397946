import React, { useState } from 'react';
import { generateKey, decryptKey, readPrivateKey, PrivateKey } from 'openpgp';
import ApiService from '../services/ApiService';

interface ModalProps {
  signingKeyOrAuthorCode: { signingKey: string } | { authorCode: string };
  sign: (key: PrivateKey) => void;
  onClose: () => void;
}

const PassphraseModal: React.FC<ModalProps> = ({ signingKeyOrAuthorCode, sign, onClose }) => {
  const [passphrase1, setPassphrase1] = useState('');
  const [passphrase2, setPassphrase2] = useState('');

  async function handleKeyCreation(authorCode: string, passphrase1: string, passphrase2: string) {
    if (passphrase1 === passphrase2) {
      try {
        const { privateKey } = await generateKey({
          type: 'ecc',
          curve: 'curve25519',
          userIDs: [{ name: `/S/${authorCode}` }],
          passphrase: passphrase1,
        });
        console.log('Signing key created successfully:');
        await ApiService.postMyKey(privateKey);
        return privateKey;
      } catch (error) {
        console.error('Error creating signing key:', error);
        alert('Failed to create signing key');
      }
    } else {
      alert('Passphrases do not match');
    }
    return null;
  }

  async function handleSigning(passphrase1: string, passphrase2: string) {
    try {
      let signKey: string | undefined;
      if ('signingKey' in signingKeyOrAuthorCode) {
        signKey = signingKeyOrAuthorCode.signingKey;
      } else {
        const newSigningKey = await handleKeyCreation(
          signingKeyOrAuthorCode.authorCode,
          passphrase1,
          passphrase2
        );
        if (newSigningKey) {
          signKey = newSigningKey;
        } else {
          return;
        }
      }
      if (signKey) {
        // decrypting the key
        const key = await decryptKey({
          privateKey: await readPrivateKey({ armoredKey: signKey }),
          passphrase: passphrase1,
        });
        sign(key);
      }
    } catch (error) {
      console.error('Error signing the file:', error);
      alert('Failed to sign the file');
    }
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="close-button" onClick={onClose}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 256 256"
          focusable="false"
          color="rgb(212, 212, 212)"
        >
          <g color="rgb(212, 212, 212)">
            <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
          </g>
        </svg>
      </div>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {'signingKey' in signingKeyOrAuthorCode ? (
          <>
            <div className="passphrase-container">
              <p>Enter passphrase to sign the file:</p>
              <input
                type="password"
                value={passphrase1}
                onChange={(e) => setPassphrase1(e.target.value)}
                placeholder="Enter passphrase"
              />
            </div>
          </>
        ) : (
          <>
            <div className="passphrase-container">
              <p>No signing key found. Enter passphrases to create a new key:</p>
              <p>
                <input
                  type="password"
                  value={passphrase1}
                  onChange={(e) => setPassphrase1(e.target.value)}
                  placeholder="Enter passphrase"
                />
              </p>
              <p>
                <input
                  type="password"
                  value={passphrase2}
                  onChange={(e) => setPassphrase2(e.target.value)}
                  placeholder="Confirm passphrase"
                />
              </p>
            </div>
          </>
        )}
        <p>
          <button className="modal-button" onClick={() => handleSigning(passphrase1, passphrase2)}>
            Sign
          </button>
        </p>
      </div>
    </div>
  );
};

export default PassphraseModal;
