/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Person
 */
export interface Person {
    /**
     * The unique code of the author.
     * @type {string}
     * @memberof Person
     */
    authorCode?: string;
    /**
     * The first name of the author.
     * @type {string}
     * @memberof Person
     */
    firstName?: string;
    /**
     * The last name of the author.
     * @type {string}
     * @memberof Person
     */
    lastName?: string;
}

/**
 * Check if a given object implements the Person interface.
 */
export function instanceOfPerson(value: object): value is Person {
    return true;
}

export function PersonFromJSON(json: any): Person {
    return PersonFromJSONTyped(json, false);
}

export function PersonFromJSONTyped(json: any, ignoreDiscriminator: boolean): Person {
    if (json == null) {
        return json;
    }
    return {
        
        'authorCode': json['author_code'] == null ? undefined : json['author_code'],
        'firstName': json['first_name'] == null ? undefined : json['first_name'],
        'lastName': json['last_name'] == null ? undefined : json['last_name'],
    };
}

export function PersonToJSON(value?: Person | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'author_code': value['authorCode'],
        'first_name': value['firstName'],
        'last_name': value['lastName'],
    };
}

