/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiPeopleCodePublicKeysGet200Response
 */
export interface ApiPeopleCodePublicKeysGet200Response {
    /**
     * A list of public keys associated with the author code.
     * @type {Array<string>}
     * @memberof ApiPeopleCodePublicKeysGet200Response
     */
    publicKeys?: Array<string>;
}

/**
 * Check if a given object implements the ApiPeopleCodePublicKeysGet200Response interface.
 */
export function instanceOfApiPeopleCodePublicKeysGet200Response(value: object): value is ApiPeopleCodePublicKeysGet200Response {
    return true;
}

export function ApiPeopleCodePublicKeysGet200ResponseFromJSON(json: any): ApiPeopleCodePublicKeysGet200Response {
    return ApiPeopleCodePublicKeysGet200ResponseFromJSONTyped(json, false);
}

export function ApiPeopleCodePublicKeysGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiPeopleCodePublicKeysGet200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'publicKeys': json['public_keys'] == null ? undefined : json['public_keys'],
    };
}

export function ApiPeopleCodePublicKeysGet200ResponseToJSON(value?: ApiPeopleCodePublicKeysGet200Response | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'public_keys': value['publicKeys'],
    };
}

