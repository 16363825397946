/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiPeopleFirstLettersGet200Response
 */
export interface ApiPeopleFirstLettersGet200Response {
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiPeopleFirstLettersGet200Response
     */
    firstLetters?: Array<string>;
}

/**
 * Check if a given object implements the ApiPeopleFirstLettersGet200Response interface.
 */
export function instanceOfApiPeopleFirstLettersGet200Response(value: object): value is ApiPeopleFirstLettersGet200Response {
    return true;
}

export function ApiPeopleFirstLettersGet200ResponseFromJSON(json: any): ApiPeopleFirstLettersGet200Response {
    return ApiPeopleFirstLettersGet200ResponseFromJSONTyped(json, false);
}

export function ApiPeopleFirstLettersGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiPeopleFirstLettersGet200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'firstLetters': json['first_letters'] == null ? undefined : json['first_letters'],
    };
}

export function ApiPeopleFirstLettersGet200ResponseToJSON(value?: ApiPeopleFirstLettersGet200Response | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'first_letters': value['firstLetters'],
    };
}

