/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiMyimagePost200Response
 */
export interface ApiMyimagePost200Response {
    /**
     * 
     * @type {string}
     * @memberof ApiMyimagePost200Response
     */
    message?: string;
}

/**
 * Check if a given object implements the ApiMyimagePost200Response interface.
 */
export function instanceOfApiMyimagePost200Response(value: object): value is ApiMyimagePost200Response {
    return true;
}

export function ApiMyimagePost200ResponseFromJSON(json: any): ApiMyimagePost200Response {
    return ApiMyimagePost200ResponseFromJSONTyped(json, false);
}

export function ApiMyimagePost200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiMyimagePost200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'message': json['message'] == null ? undefined : json['message'],
    };
}

export function ApiMyimagePost200ResponseToJSON(value?: ApiMyimagePost200Response | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'message': value['message'],
    };
}

