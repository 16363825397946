import React, { useEffect, useState } from 'react';
import { ResponseError } from '../../generated-client';
import { createMessage, sign, PrivateKey } from 'openpgp';
import ApiService from '../../services/ApiService';
import { hexToUint8Array } from '@suremark-digital/suremark-utils';
import { buildAndSignClaim } from '../../helpers/claims';

interface ModalProps {
  signatureRequestId: string;
  assetHash: string;
  assetUrl: string;
  signingKey: PrivateKey;
  comment: string;
  onClose: () => void;
}

const SigningAsset: React.FC<ModalProps> = ({
  assetHash,
  assetUrl,
  signingKey,
  onClose,
  comment,
}) => {
  const [status, setStatus] = useState<string>('Processing… Please don’t close this page');
  useEffect(() => {
    const signAsset = async () => {
      try {
        const message = await createMessage({ binary: hexToUint8Array(assetHash) });
        const signature = await sign({
          format: 'armored',
          message,
          signingKeys: [signingKey],
          detached: true,
        });
        const signedClaim = comment
          ? await buildAndSignClaim({
              type: 'comment',
              payload: { body: comment },
              assetHash,
              assetUrl,
              signingKeys: [signingKey],
            })
          : undefined;
        console.log('Asset signed successfully, now sending the signature');
        await ApiService.postSignature(assetHash, signature as string, signedClaim);
        setStatus('Asset signed successfully');
      } catch (error) {
        if (error instanceof ResponseError && error.response.status === 409) {
          setStatus(
            'There is already a signature for this file in the database. Please contact the administrator if you believe this is an error.'
          );
        } else {
          setStatus('Failed to sign the asset or send the signature');
        }
        console.error(error);
      }
    };
    signAsset();
  }, [signingKey, assetHash]);

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="close-button" onClick={onClose}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 256 256"
          focusable="false"
          color="rgb(212, 212, 212)"
        >
          <g color="rgb(212, 212, 212)">
            <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
          </g>
        </svg>
      </div>
      <h2>{status}</h2>
    </div>
  );
};
export default SigningAsset;
