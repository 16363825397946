import React, { useState } from 'react';

interface SignerProps {
  id: string;
  url: string;
  isSigned: boolean;
  assetHash?: string;
  handleSign: (data: { id: string; assetHash: string; comment: string }) => void;
}

const Signer: React.FC<SignerProps> = ({ id, url, assetHash, handleSign }) => {
  const [comment, setComment] = useState<string>('');

  return (
    <div className="signing-request-box">
      <div className="signing-request-and-comment">
        <p>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        </p>
        <input
          type="text"
          placeholder="Enter any comment (optional)"
          className="comment-input"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </div>
      <button
        className="modal-button"
        onClick={() => handleSign({ id, assetHash: assetHash!, comment })}
      >
        Sign
      </button>
    </div>
  );
};

export default Signer;
