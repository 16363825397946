import React, { useState } from 'react';
import ApiService from '../services/ApiService';
import { ResponseError } from '../generated-client';

interface RequestOTPProps {
  registration?: boolean;
  onOTPVerified: (user: { email: string }) => void;
  customMessage?: string;
}

const RequestOTP: React.FC<RequestOTPProps> = ({ onOTPVerified, registration, customMessage }) => {
  const [email, setEmail] = useState<string>('');
  const [otp, setOtp] = useState<string>('');
  const [otpRequested, setOtpRequested] = useState<boolean>(false);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleOTPRequest = async () => {
    setLoadingRequest(true);
    setError(null);
    setOtp(''); // Clear the OTP input field
    try {
      await ApiService.requestOTP(email, registration);
      setOtpRequested(true);
    } catch (error) {
      if (error instanceof ResponseError && error.response.status === 429) {
        alert('Too many requests. Please try again later.');
      } else {
        console.error('Error requesting OTP', error);
        alert('Failed to send OTP');
      }
    } finally {
      setLoadingRequest(false);
    }
  };

  const handleOTPSubmission = async () => {
    setLoadingSubmit(true);
    setError(null);
    try {
      const { token } = await ApiService.submitOTP(email, otp);
      if (token) {
        localStorage.setItem('jwt', token);
        onOTPVerified({ email });
      } else {
        console.error('Failed to verify OTP');
        setError('Invalid OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying OTP', error);
      setError('Failed to verify OTP. Please try again.');
    } finally {
      setLoadingSubmit(false);
    }
  };

  return (
    <div>
      <h3>
        {customMessage ||
          'We need to validate your email again with a One Time Password for security.'}
      </h3>
      <input
        className="email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter your email"
        disabled={loadingRequest || loadingSubmit}
      />
      <button
        className="modal-button"
        onClick={handleOTPRequest}
        disabled={loadingRequest || loadingSubmit}
      >
        {loadingRequest ? 'Requesting...' : 'Request OTP'}
      </button>
      {otpRequested && !loadingRequest && (
        <div>
          <input
            className="otp"
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            placeholder="Enter OTP"
            disabled={loadingSubmit}
          />
          <button className="modal-button" onClick={handleOTPSubmission} disabled={loadingSubmit}>
            {loadingSubmit ? 'Submitting...' : 'Submit OTP'}
          </button>
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
      )}
    </div>
  );
};

export default RequestOTP;
