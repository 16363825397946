/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ExtendedVideo } from './ExtendedVideo';
import {
    ExtendedVideoFromJSON,
    ExtendedVideoFromJSONTyped,
    ExtendedVideoToJSON,
} from './ExtendedVideo';

/**
 * 
 * @export
 * @interface ApiMyvideosGet200Response
 */
export interface ApiMyvideosGet200Response {
    /**
     * 
     * @type {Array<ExtendedVideo>}
     * @memberof ApiMyvideosGet200Response
     */
    videos?: Array<ExtendedVideo>;
}

/**
 * Check if a given object implements the ApiMyvideosGet200Response interface.
 */
export function instanceOfApiMyvideosGet200Response(value: object): value is ApiMyvideosGet200Response {
    return true;
}

export function ApiMyvideosGet200ResponseFromJSON(json: any): ApiMyvideosGet200Response {
    return ApiMyvideosGet200ResponseFromJSONTyped(json, false);
}

export function ApiMyvideosGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiMyvideosGet200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'videos': json['videos'] == null ? undefined : ((json['videos'] as Array<any>).map(ExtendedVideoFromJSON)),
    };
}

export function ApiMyvideosGet200ResponseToJSON(value?: ApiMyvideosGet200Response | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'videos': value['videos'] == null ? undefined : ((value['videos'] as Array<any>).map(ExtendedVideoToJSON)),
    };
}

