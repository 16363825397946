/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExtendedVideo
 */
export interface ExtendedVideo {
    /**
     * 
     * @type {string}
     * @memberof ExtendedVideo
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedVideo
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedVideo
     */
    videoType?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedVideo
     */
    title?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtendedVideo
     */
    isSigned?: boolean;
}

/**
 * Check if a given object implements the ExtendedVideo interface.
 */
export function instanceOfExtendedVideo(value: object): value is ExtendedVideo {
    return true;
}

export function ExtendedVideoFromJSON(json: any): ExtendedVideo {
    return ExtendedVideoFromJSONTyped(json, false);
}

export function ExtendedVideoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtendedVideo {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'] == null ? undefined : json['key'],
        'location': json['location'] == null ? undefined : json['location'],
        'videoType': json['video_type'] == null ? undefined : json['video_type'],
        'title': json['title'] == null ? undefined : json['title'],
        'isSigned': json['is_signed'] == null ? undefined : json['is_signed'],
    };
}

export function ExtendedVideoToJSON(value?: ExtendedVideo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'key': value['key'],
        'location': value['location'],
        'video_type': value['videoType'],
        'title': value['title'],
        'is_signed': value['isSigned'],
    };
}

