import React from 'react';
import './SigningRequests.css';
import Signer from './Signer';
import { ApiMySignatureRequestsGet200ResponseSignatureRequestsInner } from '../../generated-client';

interface SigningRequestsComponentProps {
  signingRequests: ApiMySignatureRequestsGet200ResponseSignatureRequestsInner[] | null;
  handleSign: (data: { id: string; assetHash: string; comment: string }) => void;
}

interface SigningRequest {
  id: string;
  url: string;
  isSigned: boolean;
  assetHash?: string;
  group?: string;
}

interface GroupedRequests {
  [key: string]: SigningRequest[];
}

const SigningRequests: React.FC<SigningRequestsComponentProps> = ({
  signingRequests,
  handleSign,
}) => {
  const groupedRequests = ((signingRequests as SigningRequest[]) || [])
    .filter((v) => !v.isSigned)
    .reduce((acc: GroupedRequests, request: SigningRequest) => {
      const group = request.group || 'ungrouped';
      if (!acc[group]) acc[group] = [];
      acc[group].push(request);
      return acc;
    }, {});

  return (
    <div>
      {signingRequests ? (
        signingRequests.length > 0 ? (
          <>
            <h2 className="container-title">Assets to Be Signed</h2>
            <div>
              {Object.entries(groupedRequests).map(([group, requests]) => (
                <div key={group} className={`group-${group}`}>
                  {group !== 'ungrouped' && <h3>{group}</h3>}
                  {requests.map((request) => (
                    <Signer
                      key={request.id}
                      id={request.id}
                      url={request.url}
                      isSigned={request.isSigned}
                      assetHash={request.assetHash}
                      handleSign={handleSign}
                    />
                  ))}
                </div>
              ))}
            </div>
          </>
        ) : (
          <p>No signing requests</p>
        )
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default SigningRequests;
