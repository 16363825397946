/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiMySignatureRequestsGet200ResponseSignatureRequestsInner
 */
export interface ApiMySignatureRequestsGet200ResponseSignatureRequestsInner {
    /**
     * The unique identifier for the signature request
     * @type {string}
     * @memberof ApiMySignatureRequestsGet200ResponseSignatureRequestsInner
     */
    id?: string;
    /**
     * The hash of the asset to be signed
     * @type {string}
     * @memberof ApiMySignatureRequestsGet200ResponseSignatureRequestsInner
     */
    assetHash?: string;
    /**
     * The URL of the asset
     * @type {string}
     * @memberof ApiMySignatureRequestsGet200ResponseSignatureRequestsInner
     */
    url?: string;
    /**
     * Indicates if the asset has been signed
     * @type {boolean}
     * @memberof ApiMySignatureRequestsGet200ResponseSignatureRequestsInner
     */
    isSigned?: boolean;
    /**
     * The logical group to which the asset belongs, e.g., tweet-123
     * @type {string}
     * @memberof ApiMySignatureRequestsGet200ResponseSignatureRequestsInner
     */
    group?: string;
}

/**
 * Check if a given object implements the ApiMySignatureRequestsGet200ResponseSignatureRequestsInner interface.
 */
export function instanceOfApiMySignatureRequestsGet200ResponseSignatureRequestsInner(value: object): value is ApiMySignatureRequestsGet200ResponseSignatureRequestsInner {
    return true;
}

export function ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerFromJSON(json: any): ApiMySignatureRequestsGet200ResponseSignatureRequestsInner {
    return ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerFromJSONTyped(json, false);
}

export function ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiMySignatureRequestsGet200ResponseSignatureRequestsInner {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'assetHash': json['asset_hash'] == null ? undefined : json['asset_hash'],
        'url': json['url'] == null ? undefined : json['url'],
        'isSigned': json['is_signed'] == null ? undefined : json['is_signed'],
        'group': json['group'] == null ? undefined : json['group'],
    };
}

export function ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerToJSON(value?: ApiMySignatureRequestsGet200ResponseSignatureRequestsInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'asset_hash': value['assetHash'],
        'url': value['url'],
        'is_signed': value['isSigned'],
        'group': value['group'],
    };
}

