import React from 'react';
import './BookingConfirmationModal.css';

interface BookingConfirmationModalProps {
  sponsorName: string;
  startTime: Date;
  onConfirm: () => void;
  onCancel: () => void;
}

const BookingConfirmationModal: React.FC<BookingConfirmationModalProps> = ({
  sponsorName,
  startTime,
  onConfirm,
  onCancel,
}) => {
  return (
    <div className="confirmation-modal">
      <div className="booking-modal-content">
        <h3>Confirm Your Selection</h3>
        <p>You've selected:</p>
        <p>
          <strong>Sponsor:</strong> {sponsorName}
        </p>
        <p>
          <strong>Time:</strong> {startTime.toLocaleString()}
        </p>
        <div className="button-container">
          <button className="confirm-btn" onClick={onConfirm}>
            Confirm
          </button>
          <button className="cancel-btn" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmationModal;
