/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiMykeyPostRequest
 */
export interface ApiMykeyPostRequest {
    /**
     * The secret key to be registered.
     * @type {string}
     * @memberof ApiMykeyPostRequest
     */
    secretKey?: string;
}

/**
 * Check if a given object implements the ApiMykeyPostRequest interface.
 */
export function instanceOfApiMykeyPostRequest(value: object): value is ApiMykeyPostRequest {
    return true;
}

export function ApiMykeyPostRequestFromJSON(json: any): ApiMykeyPostRequest {
    return ApiMykeyPostRequestFromJSONTyped(json, false);
}

export function ApiMykeyPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiMykeyPostRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'secretKey': json['secret_key'] == null ? undefined : json['secret_key'],
    };
}

export function ApiMykeyPostRequestToJSON(value?: ApiMykeyPostRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'secret_key': value['secretKey'],
    };
}

