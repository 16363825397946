import React, { useEffect, useState } from 'react';
import IconButton from './IconButton';
import { Person, SocialMediaAccount } from '../generated-client';
import ApiService from '../services/ApiService';

interface SocialMediaListProps {
  person: Person;
}

const SocialMediaList: React.FC<SocialMediaListProps> = ({ person }) => {
  const [socialMediaAccounts, setSocialMediaAccounts] = useState<SocialMediaAccount[]>([]);
  useEffect(() => {
    if (!person) {
      return;
    }
    const fetchSocialMediaAccounts = async (authorCode: string) => {
      try {
        const response = await ApiService.getSocialMediaAccounts(authorCode);
        setSocialMediaAccounts(response.socialMedia ?? []);
      } catch (error) {
        console.error('Error fetching social media accounts:', error);
      }
    };
    if (person?.authorCode) fetchSocialMediaAccounts(person.authorCode);
  }, [person]);
  return socialMediaAccounts.length ? (
    <div className="social-media-container">
      {socialMediaAccounts
        .filter((account) => account.name && account.url)
        .map((account) => (
          <div className="social-media" key={account.name}>
            <IconButton
              src={`images/${account.name!.toLowerCase()}.svg`}
              alt={account.name!}
              link={account.url!}
            />
          </div>
        ))}
    </div>
  ) : null;
};

export default SocialMediaList;
