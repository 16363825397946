/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiMybookingsGet200ResponseInner
 */
export interface ApiMybookingsGet200ResponseInner {
    /**
     * The unique ID of the booking.
     * @type {string}
     * @memberof ApiMybookingsGet200ResponseInner
     */
    bookingId?: string;
    /**
     * The ISO 8601 start time of the booking.
     * @type {string}
     * @memberof ApiMybookingsGet200ResponseInner
     */
    isoStartTime?: string;
    /**
     * The ISO 8601 end time of the booking.
     * @type {string}
     * @memberof ApiMybookingsGet200ResponseInner
     */
    isoEndTime?: string;
    /**
     * The email of the applicant.
     * @type {string}
     * @memberof ApiMybookingsGet200ResponseInner
     */
    applicantEmail?: string;
    /**
     * The name of the applicant.
     * @type {string}
     * @memberof ApiMybookingsGet200ResponseInner
     */
    applicantName?: string;
    /**
     * The email of the sponsor.
     * @type {string}
     * @memberof ApiMybookingsGet200ResponseInner
     */
    sponsorEmail?: string;
    /**
     * The name of the sponsor.
     * @type {string}
     * @memberof ApiMybookingsGet200ResponseInner
     */
    sponsorName?: string;
}

/**
 * Check if a given object implements the ApiMybookingsGet200ResponseInner interface.
 */
export function instanceOfApiMybookingsGet200ResponseInner(value: object): value is ApiMybookingsGet200ResponseInner {
    return true;
}

export function ApiMybookingsGet200ResponseInnerFromJSON(json: any): ApiMybookingsGet200ResponseInner {
    return ApiMybookingsGet200ResponseInnerFromJSONTyped(json, false);
}

export function ApiMybookingsGet200ResponseInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiMybookingsGet200ResponseInner {
    if (json == null) {
        return json;
    }
    return {
        
        'bookingId': json['booking_id'] == null ? undefined : json['booking_id'],
        'isoStartTime': json['iso_start_time'] == null ? undefined : json['iso_start_time'],
        'isoEndTime': json['iso_end_time'] == null ? undefined : json['iso_end_time'],
        'applicantEmail': json['applicant_email'] == null ? undefined : json['applicant_email'],
        'applicantName': json['applicant_name'] == null ? undefined : json['applicant_name'],
        'sponsorEmail': json['sponsor_email'] == null ? undefined : json['sponsor_email'],
        'sponsorName': json['sponsor_name'] == null ? undefined : json['sponsor_name'],
    };
}

export function ApiMybookingsGet200ResponseInnerToJSON(value?: ApiMybookingsGet200ResponseInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'booking_id': value['bookingId'],
        'iso_start_time': value['isoStartTime'],
        'iso_end_time': value['isoEndTime'],
        'applicant_email': value['applicantEmail'],
        'applicant_name': value['applicantName'],
        'sponsor_email': value['sponsorEmail'],
        'sponsor_name': value['sponsorName'],
    };
}

