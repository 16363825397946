/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApiMySignatureRequestsSignPostRequestClaim } from './ApiMySignatureRequestsSignPostRequestClaim';
import {
    ApiMySignatureRequestsSignPostRequestClaimFromJSON,
    ApiMySignatureRequestsSignPostRequestClaimFromJSONTyped,
    ApiMySignatureRequestsSignPostRequestClaimToJSON,
} from './ApiMySignatureRequestsSignPostRequestClaim';

/**
 * 
 * @export
 * @interface ApiMySignatureRequestsSignPostRequest
 */
export interface ApiMySignatureRequestsSignPostRequest {
    /**
     * The signature of the asset
     * @type {string}
     * @memberof ApiMySignatureRequestsSignPostRequest
     */
    assetSignature: string;
    /**
     * The hash of the asset
     * @type {string}
     * @memberof ApiMySignatureRequestsSignPostRequest
     */
    assetHash: string;
    /**
     * 
     * @type {ApiMySignatureRequestsSignPostRequestClaim}
     * @memberof ApiMySignatureRequestsSignPostRequest
     */
    claim?: ApiMySignatureRequestsSignPostRequestClaim;
}

/**
 * Check if a given object implements the ApiMySignatureRequestsSignPostRequest interface.
 */
export function instanceOfApiMySignatureRequestsSignPostRequest(value: object): value is ApiMySignatureRequestsSignPostRequest {
    if (!('assetSignature' in value) || value['assetSignature'] === undefined) return false;
    if (!('assetHash' in value) || value['assetHash'] === undefined) return false;
    return true;
}

export function ApiMySignatureRequestsSignPostRequestFromJSON(json: any): ApiMySignatureRequestsSignPostRequest {
    return ApiMySignatureRequestsSignPostRequestFromJSONTyped(json, false);
}

export function ApiMySignatureRequestsSignPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiMySignatureRequestsSignPostRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'assetSignature': json['asset_signature'],
        'assetHash': json['asset_hash'],
        'claim': json['claim'] == null ? undefined : ApiMySignatureRequestsSignPostRequestClaimFromJSON(json['claim']),
    };
}

export function ApiMySignatureRequestsSignPostRequestToJSON(value?: ApiMySignatureRequestsSignPostRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'asset_signature': value['assetSignature'],
        'asset_hash': value['assetHash'],
        'claim': ApiMySignatureRequestsSignPostRequestClaimToJSON(value['claim']),
    };
}

