import { DefaultApi } from '../generated-client/apis/DefaultApi';
import { Configuration, ApiPeopleFirstLettersGet200Response, Person } from '../generated-client';
import { SignedClaim } from '@suremark-digital/suremark-utils';

class ApiService {
  static defaultApi = new DefaultApi(
    new Configuration({ basePath: process.env.REACT_APP_API_BASE_URL })
  );
  static async search(searchQuery: string): Promise<{ people?: Person[] }> {
    return await this.defaultApi.apiPeopleGet({ search: searchQuery });
  }
  static async firstLetters(): Promise<ApiPeopleFirstLettersGet200Response> {
    return await this.defaultApi.apiPeopleFirstLettersGet();
  }
  static getPersonImageURL(person: Person): string {
    return `${process.env.REACT_APP_API_BASE_URL}/api/people/${person.authorCode}/image`;
  }
  static async postImage(image: string) {
    const jwt = localStorage.getItem('jwt');
    return await this.defaultApi.apiMyimagePost(
      { apiMyimagePostRequest: { image } },
      {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
        // setting Authorization is wiping out the Content-Type header, so we need to set it manually
      }
    );
  }
  static async getVideos(authorCode: string) {
    return await this.defaultApi.apiPeopleCodeVideosGet({ code: authorCode });
  }
  static async getMyVideos() {
    const jwt = localStorage.getItem('jwt');
    return await this.defaultApi.apiMyvideosGet({ headers: { Authorization: `Bearer ${jwt}` } });
  }
  static async getMySignatureRequests() {
    const jwt = localStorage.getItem('jwt');
    return await this.defaultApi.apiMySignatureRequestsGet({
      headers: { Authorization: `Bearer ${jwt}` },
    });
  }
  static async getSocialMediaAccounts(authorCode: string) {
    return await this.defaultApi.apiPeopleCodeSocialMediaGet({ code: authorCode });
  }
  static async getPerson(authorCode: string): Promise<Person | undefined> {
    return (await this.defaultApi.apiPeopleCodeGet({ code: authorCode })).person;
  }
  static async requestOTP(email: string, registration?: boolean) {
    return await this.defaultApi.apiSendOtpPost({
      apiSendOtpPostRequest: { email, registration: registration ? true : false },
    });
  }
  static async submitOTP(email: string, otp: string) {
    return await this.defaultApi.apiVerifyOtpPost({ apiVerifyOtpPostRequest: { email, otp } });
  }
  static async postGrantCode(submissionId: string, grantCode: string) {
    return await this.defaultApi.apiGrantPost({ apiGrantPostRequest: { grantCode, submissionId } });
  }
  static async getMyKey() {
    const jwt = localStorage.getItem('jwt');
    return await this.defaultApi.apiMykeyGet({ headers: { Authorization: `Bearer ${jwt}` } });
  }
  static async postMyKey(secretKey: string) {
    const jwt = localStorage.getItem('jwt');
    return await this.defaultApi.apiMykeyPost(
      { apiMykeyPostRequest: { secretKey } },
      {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
        // setting Authorization is wiping out the Content-Type header, so we need to set it manually
      }
    );
  }
  static async submitPost(location: string) {
    const jwt = localStorage.getItem('jwt');
    return await this.defaultApi.apiPostPost(
      { apiPostPostRequest: { location } },
      {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
        // setting Authorization is wiping out the Content-Type header, so we need to set it manually
      }
    );
  }
  static async postSignature(assetHash: string, assetSignature: string, signedClaim?: SignedClaim) {
    const jwt = localStorage.getItem('jwt');
    const apiMySignatureRequestsSignPostRequest: {
      assetSignature: string;
      assetHash: string;
      claim?: { claim: any; claimSignature: string };
    } = { assetSignature, assetHash };
    if (signedClaim) {
      apiMySignatureRequestsSignPostRequest.claim = {
        claim: signedClaim.claim,
        claimSignature: signedClaim.signature,
      };
    }
    return await this.defaultApi.apiMySignatureRequestsSignPost(
      { apiMySignatureRequestsSignPostRequest },
      {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
        // setting Authorization is wiping out the Content-Type header, so we need to set it manually
      }
    );
  }
  // check if the cookie or token is valid, returns valid token
  static async verifyToken(onUserDataChange: (user: { email: string }) => void): Promise<void> {
    const jwt = localStorage.getItem('jwt');
    const { jwt: newToken, email } = await this.defaultApi.authVerifyTokenGet({
      credentials: 'include', // impersonating cookie
      headers: jwt ? { Authorization: `Bearer ${jwt}` } : undefined,
    });
    if (newToken && newToken !== jwt) {
      localStorage.setItem('jwt', newToken);
    }
    if (email) {
      onUserDataChange({ email });
    }
  }
  static async getMyVouchingSummary(submissionId: string) {
    const jwt = localStorage.getItem('jwt');
    return await this.defaultApi.apiMyvouchingGet(
      { submissionId },
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    );
  }
  static async getMyBookings() {
    const jwt = localStorage.getItem('jwt');
    return await this.defaultApi.apiMybookingsGet({
      headers: { Authorization: `Bearer ${jwt}` },
    });
  }
  static async getAvailableSlots(date: string): Promise<string[]> {
    const jwt = localStorage.getItem('jwt');
    return await this.defaultApi.apiAvailableslotsGet(
      { selectedDate: date },
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    );
  }
  static async postMyBooking(date: Date, voucherId: string, submissionId: string) {
    const jwt = localStorage.getItem('jwt');
    return await this.defaultApi.apiMybookingsPost(
      { apiMybookingsPostRequest: { date, contactId: voucherId, submissionId } },
      {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      }
    );
  }
  static async postUrls(
    submissionId: string,
    applicantUrl: string,
    vouchers: { id: string; url: string }[]
  ) {
    const jwt = localStorage.getItem('jwt');
    return await this.defaultApi.apiMyvouchingUrlsPost(
      { apiMyvouchingUrlsPostRequest: { submissionId, applicantUrl, vouchers } },
      {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` },
      }
    );
  }
  static async getMyAuthorCode() {
    const jwt = localStorage.getItem('jwt');
    return await this.defaultApi.apiMycodeGet({ headers: { Authorization: `Bearer ${jwt}` } });
  }
}

export default ApiService;
