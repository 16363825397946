/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiMyvouchingGet200Response
 */
export interface ApiMyvouchingGet200Response {
    /**
     * ID of the applicant
     * @type {string}
     * @memberof ApiMyvouchingGet200Response
     */
    applicantId?: string;
    /**
     * Name of the applicant
     * @type {string}
     * @memberof ApiMyvouchingGet200Response
     */
    applicantName?: string;
    /**
     * ID of the first voucher
     * @type {string}
     * @memberof ApiMyvouchingGet200Response
     */
    voucher1Id?: string;
    /**
     * Name of the first voucher
     * @type {string}
     * @memberof ApiMyvouchingGet200Response
     */
    voucher1Name?: string;
    /**
     * ID of the second voucher
     * @type {string}
     * @memberof ApiMyvouchingGet200Response
     */
    voucher2Id?: string;
    /**
     * Name of the second voucher
     * @type {string}
     * @memberof ApiMyvouchingGet200Response
     */
    voucher2Name?: string;
    /**
     * Email of the applicant
     * @type {string}
     * @memberof ApiMyvouchingGet200Response
     */
    applicantEmail?: string;
    /**
     * Email of the first voucher
     * @type {string}
     * @memberof ApiMyvouchingGet200Response
     */
    voucher1Email?: string;
    /**
     * Email of the second voucher
     * @type {string}
     * @memberof ApiMyvouchingGet200Response
     */
    voucher2Email?: string;
}

/**
 * Check if a given object implements the ApiMyvouchingGet200Response interface.
 */
export function instanceOfApiMyvouchingGet200Response(value: object): value is ApiMyvouchingGet200Response {
    return true;
}

export function ApiMyvouchingGet200ResponseFromJSON(json: any): ApiMyvouchingGet200Response {
    return ApiMyvouchingGet200ResponseFromJSONTyped(json, false);
}

export function ApiMyvouchingGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiMyvouchingGet200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'applicantId': json['applicantId'] == null ? undefined : json['applicantId'],
        'applicantName': json['applicantName'] == null ? undefined : json['applicantName'],
        'voucher1Id': json['voucher1Id'] == null ? undefined : json['voucher1Id'],
        'voucher1Name': json['voucher1Name'] == null ? undefined : json['voucher1Name'],
        'voucher2Id': json['voucher2Id'] == null ? undefined : json['voucher2Id'],
        'voucher2Name': json['voucher2Name'] == null ? undefined : json['voucher2Name'],
        'applicantEmail': json['applicantEmail'] == null ? undefined : json['applicantEmail'],
        'voucher1Email': json['voucher1Email'] == null ? undefined : json['voucher1Email'],
        'voucher2Email': json['voucher2Email'] == null ? undefined : json['voucher2Email'],
    };
}

export function ApiMyvouchingGet200ResponseToJSON(value?: ApiMyvouchingGet200Response | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'applicantId': value['applicantId'],
        'applicantName': value['applicantName'],
        'voucher1Id': value['voucher1Id'],
        'voucher1Name': value['voucher1Name'],
        'voucher2Id': value['voucher2Id'],
        'voucher2Name': value['voucher2Name'],
        'applicantEmail': value['applicantEmail'],
        'voucher1Email': value['voucher1Email'],
        'voucher2Email': value['voucher2Email'],
    };
}

