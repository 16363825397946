/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApiMySignatureRequestsSignPostRequestClaimClaim } from './ApiMySignatureRequestsSignPostRequestClaimClaim';
import {
    ApiMySignatureRequestsSignPostRequestClaimClaimFromJSON,
    ApiMySignatureRequestsSignPostRequestClaimClaimFromJSONTyped,
    ApiMySignatureRequestsSignPostRequestClaimClaimToJSON,
} from './ApiMySignatureRequestsSignPostRequestClaimClaim';

/**
 * An object representing a signed claim.
 * @export
 * @interface ApiMySignatureRequestsSignPostRequestClaim
 */
export interface ApiMySignatureRequestsSignPostRequestClaim {
    /**
     * 
     * @type {ApiMySignatureRequestsSignPostRequestClaimClaim}
     * @memberof ApiMySignatureRequestsSignPostRequestClaim
     */
    claim?: ApiMySignatureRequestsSignPostRequestClaimClaim;
    /**
     * The signature associated with the claim.
     * @type {string}
     * @memberof ApiMySignatureRequestsSignPostRequestClaim
     */
    claimSignature?: string;
}

/**
 * Check if a given object implements the ApiMySignatureRequestsSignPostRequestClaim interface.
 */
export function instanceOfApiMySignatureRequestsSignPostRequestClaim(value: object): value is ApiMySignatureRequestsSignPostRequestClaim {
    return true;
}

export function ApiMySignatureRequestsSignPostRequestClaimFromJSON(json: any): ApiMySignatureRequestsSignPostRequestClaim {
    return ApiMySignatureRequestsSignPostRequestClaimFromJSONTyped(json, false);
}

export function ApiMySignatureRequestsSignPostRequestClaimFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiMySignatureRequestsSignPostRequestClaim {
    if (json == null) {
        return json;
    }
    return {
        
        'claim': json['claim'] == null ? undefined : ApiMySignatureRequestsSignPostRequestClaimClaimFromJSON(json['claim']),
        'claimSignature': json['claim_signature'] == null ? undefined : json['claim_signature'],
    };
}

export function ApiMySignatureRequestsSignPostRequestClaimToJSON(value?: ApiMySignatureRequestsSignPostRequestClaim | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'claim': ApiMySignatureRequestsSignPostRequestClaimClaimToJSON(value['claim']),
        'claim_signature': value['claimSignature'],
    };
}

