/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Person } from './Person';
import {
    PersonFromJSON,
    PersonFromJSONTyped,
    PersonToJSON,
} from './Person';

/**
 * 
 * @export
 * @interface ApiPeopleGet200Response
 */
export interface ApiPeopleGet200Response {
    /**
     * 
     * @type {Array<Person>}
     * @memberof ApiPeopleGet200Response
     */
    people?: Array<Person>;
}

/**
 * Check if a given object implements the ApiPeopleGet200Response interface.
 */
export function instanceOfApiPeopleGet200Response(value: object): value is ApiPeopleGet200Response {
    return true;
}

export function ApiPeopleGet200ResponseFromJSON(json: any): ApiPeopleGet200Response {
    return ApiPeopleGet200ResponseFromJSONTyped(json, false);
}

export function ApiPeopleGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiPeopleGet200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'people': json['people'] == null ? undefined : ((json['people'] as Array<any>).map(PersonFromJSON)),
    };
}

export function ApiPeopleGet200ResponseToJSON(value?: ApiPeopleGet200Response | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'people': value['people'] == null ? undefined : ((value['people'] as Array<any>).map(PersonToJSON)),
    };
}

