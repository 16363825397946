import React from 'react';

const ApplicationIsComplete: React.FC = () => {
  return (
    <>
      <h3>Your videos have been approved and will be posted in our directory.</h3>
      <h1>
        <br />
        Your Application is complete.
      </h1>
      <h1>
        Here's what happens next...
        <br />
        <br />
      </h1>
      <div>
        <ul className="list-left-align">
          <li className="bulleted">
            The application committee will review your application, conducting further online
            checks, prioritizing applicants with larger social media followings.
          </li>
          <li className="bulleted">
            If approved, you'll be required to pay the balance due and receive your credentials.
            Your SureMark Digital ID will be valid for five years.
          </li>
          <li className="bulleted">
            Your followers will easily recognize your authorized content from any fakes!
          </li>
        </ul>
      </div>
    </>
  );
};

export default ApplicationIsComplete;
